import React from 'react';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useAxios } from '../../hooks';
import { Button, Input, Context, Layout } from '../../components';

const SignIn = () => {
	const [context, setContext] = React.useContext(Context);

	const {
		register: registerSignIn,
		handleSubmit: handleSubmitSignIn,
		reset: resetSignIn,
		formState: { errors: errorsSignIn },
	} = useForm();

	const {
		loading: loadingSignIn,
		response: responseSignIn,
		error: errorSignIn,
		fetch: fetchSignIn,
	} = useAxios();

	const onSubmit = (data) => {
		fetchSignIn({
			method: 'POST',
			url: '/auth/public/login',
			data,
		});
	};

	React.useEffect(() => {
		if (responseSignIn && responseSignIn?.data?.userRole === 'ADMIN') {
			const signed = {
				time: Date.now(),
				data: responseSignIn.data,
			};
			localStorage.setItem('signed', JSON.stringify(signed));
			setContext({
				...context,
				signed,
				state: {},
			});
		}
	}, [responseSignIn]);

	React.useEffect(() => {
		if (errorSignIn) {
			resetSignIn({
				password: '',
			});
			toast.error(
				errorSignIn.response?.data?.message
					? errorSignIn.response.data.message
					: errorSignIn.message
			);
		}
	}, [errorSignIn]);

	return (
		<Layout.Public>
			<form
				className="w-full sm:w-[360px] sm:max-w-full"
				onSubmit={handleSubmitSignIn(onSubmit)}
				noValidate
			>
				<div className="my-6">
					<img
						src={require('../../assets/logo-connection.png')}
						alt=""
						className="mx-auto h-32"
					/>
				</div>
				<div className="mb-12 text-center">
					<h2 className="text-xl font-medium !leading-tight text-slate-700">
						Hoşgeldiniz.
					</h2>
				</div>

				<Input
					type="text"
					name="username"
					label="Kullanıcı Adı"
					defaultValue="admin1"
					register={registerSignIn}
					validation={{
						required: 'Bu alan zorunludur.',
					}}
					errors={errorsSignIn}
					size="xl"
					className={{ input: '!text-sm' }}
				/>
				<div className="relative mt-6">
					<Input
						type="password"
						name="password"
						label="Parola"
						defaultValue="3277Ctis."
						register={registerSignIn}
						validation={{
							required: 'Bu alan zorunludur.',
						}}
						errors={errorsSignIn}
						size="xl"
						className={{ input: '!text-sm' }}
					/>
					<NavLink
						to="/parolami-unuttum"
						className="absolute top-0 right-0 text-sm text-blue-700 hover:underline"
					>
						Parolamı unuttum
					</NavLink>
				</div>
				<Button
					type="submit"
					className="mt-6 w-full !text-sm"
					loading={loadingSignIn}
					disabled={
						Object.keys(errorsSignIn).length > 0 || loadingSignIn
					}
					size="xl"
				>
					Giriş Yap
				</Button>

				<div className="mt-6 text-center text-sm">
					Hesabın yok mu?{' '}
					<NavLink
						to="/uye-ol"
						className="text-blue-700 hover:underline"
					>
						Üye ol
					</NavLink>
				</div>
			</form>
		</Layout.Public>
	);
};

export default SignIn;
