import React from 'react';

const Thumbnails = ({ images, max, className }) => (
	<div className={`flex ${className || ''}`}>
		{images &&
			images.slice(0, max).map((image, index) => (
				<div
					className="relative flex aspect-square overflow-hidden rounded-full border-2 shadow-lg  [&:not(:first-child)]:-ml-6"
					key={index}
				>
					{image?.url !== 'null' ? (
						<img
							src={image.url}
							alt={image.name}
							className="h-12 w-12 object-cover p-1 bg-white"
						/>
					) : (
						<div className="flex h-12 w-12 shrink-0 items-center justify-center rounded-full text-center text-base font-medium uppercase text-white bg-blue-500">
							{image.name
								.split(' ')
								.slice(0, 2)
								.map((string) =>
									string.charAt(0).toUpperCase()
								)}
						</div>
					)}
					{images.length > 1 &&
						images.slice(0, max).length === index + 1 && (
							<div className="absolute inset-0 flex items-center justify-center bg-blue-500/75 text-center text-xs font-medium backdrop-blur-sm text-white">
								{images.length}
							</div>
						)}
				</div>
			))}
	</div>
);

export default Thumbnails;
