import React from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { BsFillXCircleFill } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
	Badge,
	Button,
	Input,
	Item,
	Modal,
	Page,
	Select,
	Table,
	Time,
} from '../../components';
import { useAxios } from '../../hooks';

const GamePuzzleMatch = () => {
	const [showEdit, setShowEdit] = React.useState(false);
	const [currentImage, setCurrentImage] = React.useState([]);
	const [brandStyles, setBrandStyles] = React.useState([]);
	const [params, setParams] = useSearchParams();
	const {
		register: registerSaveGame,
		unregister: unregisterSaveGame,
		// watch: watchSaveGame,
		setValue: setValueSaveGame,
		handleSubmit: handleSubmitSaveGame,
		reset: resetSaveGame,
		formState: { errors: errorsSaveGame },
	} = useForm();

	const api = {
		getGames: useAxios(),
		saveGame: useAxios(),
		removeGame: useAxios(),
		getBrandStyles: useAxios(),
	};

	/* getBrandStyles */

	const getBrandStyles = () => {
		api.getBrandStyles.fetch({
			method: 'GET',
			url: '/brand/brandStyleList/withIcon',
		});
	};

	React.useEffect(() => {
		if (api.getBrandStyles.response) {
			console.log(api.getBrandStyles.response);
		}
	}, [api.getBrandStyles.response]);

	React.useEffect(() => {
		if (api.getBrandStyles.error) {
			toast.error(api.getBrandStyles.error.message);
		}
	}, [api.getBrandStyles.error]);

	React.useEffect(() => {
		getBrandStyles();
	}, []);

	/* getGames */

	const getGames = () => {
		api.getGames.fetch({
			method: 'GET',
			url: '/game/admin/getPuzzleMatchList?page=0&size=9999',
		});
	};

	React.useEffect(() => {
		if (api.getGames.response) {
			console.log(api.getGames.response);
		}
	}, [api.getGames.response]);

	React.useEffect(() => {
		if (api.getGames.error) {
			toast.error(api.getGames.error.message);
		}
	}, [api.getGames.error]);

	React.useEffect(() => {
		getGames();
	}, []);

	/* saveGame */

	const saveGame = async (data) => {
		const formData = new FormData();

		formData.append(
			'savePuzzleMatchDTO',
			JSON.stringify({
				...data,
				active: !!(data.active === 'true' || data.active === true),
				dateBegin:
					data.dateBegin !== ''
						? moment.utc(data.dateBegin).format()
						: '',
				dateEnd:
					data.dateEnd !== ''
						? moment.utc(data.dateEnd).format()
						: '',
				...(currentImage.length &&
				currentImage[0] &&
				typeof currentImage[0] === 'string'
					? { contents: [currentImage[0]] }
					: null),
			})
		);

		if (
			currentImage.length &&
			currentImage[0] &&
			currentImage[0] instanceof File
		) {
			formData.append('icon', currentImage[0]);
		}

		api.saveGame.fetch({
			method: 'POST',
			url: '/game/admin/puzzleMatch/save',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	};

	React.useEffect(() => {
		if (api.saveGame.response) {
			resetSaveGame();
			setCurrentImage([]);
			setBrandStyles([]);
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getGames();
		}
	}, [api.saveGame.response]);

	React.useEffect(() => {
		if (api.saveGame.error) {
			toast.error(api.saveGame.error.message);
		}
	}, [api.saveGame.error]);

	const removeImage = (position) => {
		const images = [...currentImage];
		images.splice(position, 1);
		setCurrentImage(images);
	};

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (
			showEdit.id &&
			api.getGames.response &&
			api.getBrandStyles.response
		) {
			const { id } = showEdit;
			const item =
				api.getGames.response.data.content[
					api.getGames.response.data.content.findIndex(
						(object) => object.id === id
					)
				];
			const values = [
				{ title: item.title },
				{ description: item.description },
				{ buttonText: item.buttonText },
				{ inGameDescription: item.inGameDescription },
				{ score: item.score },
				{ trueAnswerText: item.trueAnswerText },

				{
					dateBegin: moment(item.dateBegin).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
				{ active: item.active },
			];

			if (item.brandStyleDTOList) {
				const brandStyleDTOList = [];
				item.brandStyleDTOList.forEach((value) => {
					brandStyleDTOList.push(value.id);
				});
				setBrandStyles(brandStyleDTOList);
			}

			values.forEach((value) => {
				setValueSaveGame(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});

			if (item.icon) {
				const images = [];
				images.push(item.icon);
				setCurrentImage(images);
			}
		}
	}, [showEdit.id && api.getGames.response && api.getBrandStyles.response]);

	const handleAddBrandStyle = () => {
		setBrandStyles([...brandStyles, 0]);
	};

	const handleRemoveBrandStyle = (index) => {
		const brandStylesTemp = [...brandStyles];
		brandStylesTemp.splice(index, 1);
		unregisterSaveGame(`brandStyleIdList[${index}]`);
		setBrandStyles([...brandStylesTemp]);
	};

	/* removeGame */

	const removeGame = (id) => {
		api.removeGame.fetch({
			method: 'POST',
			url: '/game/admin/puzzleMatch/delete',
			data: id,
		});
	};

	React.useEffect(() => {
		if (api.removeGame.response) {
			setShowEdit(false);
			toast.success('Silindi.');
			getGames();
		}
	}, [api.removeGame.response]);

	React.useEffect(() => {
		if (api.removeGame.error) {
			toast.error(api.removeGame.error.message);
		}
	}, [api.removeGame.error]);

	/* return */

	return (
		<Page>
			<Page.Header header="Oyunlar" title="Eşini Bul">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>
			<Page.Body
				loading={api.getGames.loading || api.getBrandStyles.loading}
				show={!api.getGames.loading && api.getGames.response}
			>
				<Page.Content>
					<Table>
						<Table.Head
							data={['Id', 'Oyun', 'Tarih', 'Durum', '']}
						/>
						<Table.Body>
							{api.getGames.response?.data?.content?.length &&
								api.getGames.response.data.content.map(
									(item, index) => (
										<Table.Row key={index}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column>
												<Item
													image={
														item.icon
															? item.icon
															: require('../../assets/logo-connection.png')
													}
													title={item.title}
													description={
														item.description
													}
													className="w-96 [&_img]:object-contain [&_img]:p-2 [&_img]:bg-white"
												/>
											</Table.Column>
											<Table.Column>
												<Time
													dateBegin={moment(
														item.dateBegin
													).format('DD.MM.YYYY')}
													timeBegin={moment(
														item.dateBegin
													).format('HH:mm:ss')}
													dateEnd={moment(
														item.dateEnd
													).format('DD.MM.YYYY')}
													timeEnd={moment(
														item.dateEnd
													).format('HH:mm:ss')}
												/>
											</Table.Column>
											<Table.Column>
												{item.active ? (
													<Badge variant="success">
														<div
															className="aspect-square w-1.5 animate-pulse
													rounded-full bg-green-500"
														/>
														Aktif
													</Badge>
												) : (
													<Badge variant="danger">
														<div className="aspect-square w-1.5 rounded-full bg-red-500" />
														Pasif
													</Badge>
												)}
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<Button
													size="xs"
													variant="secondary-outline"
													className="inline-flex"
													onClick={() => {
														setShowEdit({
															id: item.id,
														});
													}}
												>
													Düzenle
												</Button>
											</Table.Column>
										</Table.Row>
									)
								)}
						</Table.Body>
					</Table>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getGames.loading ||
					api.saveGame.loading ||
					api.removeGame.loading ||
					api.getBrandStyles.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					setCurrentImage([]);
					setBrandStyles([]);
					resetSaveGame();
				}}
			>
				<form onSubmit={handleSubmitSaveGame(saveGame)} noValidate>
					<div className="px-6">
						<Modal.Section title="İçerik">
							<Input
								type="text"
								name="title"
								label="Başlık"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="description"
								label="Açıklama"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="number"
								name="score"
								label="Puan"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>

							<Input
								type="datetime-local"
								name="dateBegin"
								label="Başlangıç Tarihi"
								grid
								placeholder="Lütfen giriniz."
								defaultValue={moment(Date.now()).format(
									'YYYY-MM-DD HH:mm'
								)}
								register={registerSaveGame}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="datetime-local"
								name="dateEnd"
								label="Bitiş Tarihi"
								grid
								placeholder="Lütfen giriniz."
								defaultValue={moment(Date.now())
									.add(1, 'years')
									.format('YYYY-MM-DD HH:mm')}
								register={registerSaveGame}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Select
								name="active"
								label="Durum"
								grid
								options={[
									{
										name: 'Aktif',
										value: true,
									},
									{
										name: 'Pasif',
										value: false,
									},
								]}
								defaultValue
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
						</Modal.Section>

						<Modal.Section title="Görünüm">
							<Input
								type="text"
								name="buttonText"
								label="Düğme Metni"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="inGameDescription"
								label="Oyun İçi Açıklama"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="trueAnswerText"
								label="Doğru Yanıt Metni"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="backgroundPattern"
								label="Arkaplan Rengi"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								defaultValue="#"
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
						</Modal.Section>

						<Modal.Section title="Ürünler">
							{brandStyles.length ? (
								brandStyles.map(
									(brandStyle, brandStyleIndex) => (
										<div
											className="relative pr-8"
											key={brandStyleIndex}
										>
											<Select
												name={`brandStyleIdList[${brandStyleIndex}]`}
												label={`Ürün ${
													brandStyleIndex + 1
												}`}
												grid
												options={[
													{
														name: 'Lütfen Seçiniz',
														value: 0,
													},
													...(api.getBrandStyles
														.response?.data?.content
														?.length
														? api.getBrandStyles.response.data.content.map(
																(item) => ({
																	name: item.name,
																	value: item.id,
																})
														  )
														: []),
												]}
												defaultValue={brandStyle}
												register={registerSaveGame}
												validation={{
													required:
														'Bu alan zorunludur.',
												}}
												errors={errorsSaveGame}
												size="lg"
												className={{
													select: '!text-sm',
												}}
											/>
											<button
												className="absolute top-3.5 right-0"
												type="button"
												onClick={() =>
													handleRemoveBrandStyle(
														brandStyleIndex
													)
												}
											>
												<BsFillXCircleFill className="translate h-5 w-5 rounded-full border-2 bg-white border-white text-red-500" />
											</button>
										</div>
									)
								)
							) : (
								<div className="rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
									Ürün bulunamadı
								</div>
							)}

							<button
								className={`flex h-9 w-full items-center justify-center whitespace-nowrap
											rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none
											transition duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600
										`}
								type="button"
								onClick={() => {
									handleAddBrandStyle();
								}}
							>
								Yeni Ürün Ekle
							</button>
						</Modal.Section>

						<Modal.Section title="Görsel">
							{/* images start */}
							<div className="group">
								<div className="grid grid-cols-3 items-center">
									<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
										Simge
									</label>
									<div className="col-span-2">
										<div className="mb-4 grid grid-cols-4 gap-4">
											{currentImage &&
											currentImage.length ? (
												currentImage.map(
													(image, index) => (
														<div
															className="relative inline-block"
															key={index}
														>
															<img
																src={
																	typeof image ===
																	'object'
																		? window.URL.createObjectURL(
																				image
																		  )
																		: image
																}
																key={index}
																alt=""
																className="aspect-square h-full w-full
															rounded-lg object-cover shadow bg-slate-50"
															/>
															<button
																type="button"
																className="absolute top-0 right-0 translate-x-2 -translate-y-2"
																onClick={() =>
																	removeImage(
																		index
																	)
																}
															>
																<BsFillXCircleFill
																	className="translate h-5 w-5
															rounded-full border-2 bg-white border-white text-red-500"
																/>
															</button>
														</div>
													)
												)
											) : (
												<div
													className="col-span-4 rounded-md p-3 text-center
											text-xs text-slate-400 bg-slate-50"
												>
													Görsel Bulunamadı
												</div>
											)}
										</div>
										<div className="relative">
											<input
												type="file"
												accept="image/*"
												onChange={(event) => {
													if (
														event?.target
															?.files?.[0]
													) {
														setCurrentImage([
															...currentImage,
															event.target
																.files[0],
														]);
													}
												}}
												className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
											/>
											{!currentImage.length && (
												<div
													className={`flex h-9 w-full items-center justify-center whitespace-nowrap
                                                        rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none
                                                        transition
                                                        duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600
                                                    `}
												>
													Yeni Görsel Ekle
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
							{/* images end */}
						</Modal.Section>
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveGame}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								className="w-32"
								onClick={() => {
									removeGame(showEdit.id);
								}}
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={Object.keys(errorsSaveGame).length > 0}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default GamePuzzleMatch;
