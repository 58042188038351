import React from 'react';

import Body from './Body';
import Content from './Content';
import Dashboard from './Dashboard';
import Footer from './Footer';
import Header from './Header';

const Page = ({ children, className }) => (
	<main
		className={`mx-auto flex w-full grow flex-col gap-8 overflow-y-auto p-4 lg:container lg:p-8 ${
			className || ''
		}`}
	>
		{children}
	</main>
);

Page.Body = Body;
Page.Content = Content;
Page.Dashboard = Dashboard;
Page.Footer = Footer;
Page.Header = Header;

export default Page;
