import React from 'react';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { IoAlertCircle } from 'react-icons/io5';

import { useAxios } from '../../hooks';
import { Button, Layout } from '../../components';

const SignUp = () => {
	const {
		register: registerSignUp,
		handleSubmit: handleSubmitSignUp,
		reset: resetSignUp,
		formState: { errors: errorsSignUp },
	} = useForm();

	const {
		loading: loadingSignUp,
		response: responseSignUp,
		error: errorSignUp,
		fetch: fetchSignUp,
	} = useAxios();

	const onSubmit = (data) => {
		fetchSignUp({
			method: 'POST',
			// url: '/auth/public/login',
			data,
		});
	};

	React.useEffect(() => {
		if (responseSignUp) {
			console.log(responseSignUp);
		}
	}, [responseSignUp]);

	React.useEffect(() => {
		if (errorSignUp) {
			resetSignUp();
			toast.error(errorSignUp.message);
		}
	}, [errorSignUp]);

	return (
		<Layout.Public>
			<form
				className="w-full sm:w-[560px] sm:max-w-full"
				onSubmit={handleSubmitSignUp(onSubmit)}
				noValidate
			>
				<div className="mt-6 mb-12 text-center">
					<h2 className="text-xl font-medium !leading-tight text-slate-700">
						Üye Ol.
					</h2>
				</div>

				<div className="grid gap-6 sm:grid-cols-2">
					{/*
					"tenantName":"tenant1",

					"salesDocumentNumber":"1231245544331414",
					"salesDocumentType":"PTT",

String countryCode;
String uniqueField;
String tenantName;
String userType;
String dealerSignName;
String salesDocumentNumber;
String salesDocumentType;
String passwordAgain;

					*/}

					{/* name */}
					<div className={`group ${errorsSignUp.name && 'invalid'}`}>
						<label className="mb-2 flex gap-1 text-sm font-normal text-slate-700 group-[.invalid]:!text-red-500">
							Ad
						</label>
						<input
							type="text"
							placeholder="Lütfen giriniz"
							className="relative z-10 w-full rounded-lg border-2 p-4 text-sm placeholder-slate-400 outline-none transition duration-300 text-slate-500 bg-slate-50 border-slate-200 focus:ring-blue-500 focus:border-blue-500 group-[.invalid]:text-red-500 group-[.invalid]:border-red-500"
							{...registerSignUp('name', {
								required: 'Bu alan zorunludur.',
							})}
						/>
						{errorsSignUp.name && (
							<div className="relative -top-2 inline-flex w-full gap-1 rounded-b-lg p-2 pt-4 text-sm text-white bg-red-500">
								<IoAlertCircle className="h-5 w-5 shrink-0" />
								{errorsSignUp.name.message}
							</div>
						)}
					</div>

					{/* surname */}
					<div
						className={`group ${errorsSignUp.surname && 'invalid'}`}
					>
						<label className="mb-2 flex gap-1 text-sm font-normal text-slate-700 group-[.invalid]:!text-red-500">
							Soyad
						</label>
						<input
							type="text"
							placeholder="Lütfen giriniz"
							className="relative z-10 w-full rounded-lg border-2 p-4 text-sm placeholder-slate-400 outline-none transition duration-300 text-slate-500 bg-slate-50 border-slate-200 focus:ring-blue-500 focus:border-blue-500 group-[.invalid]:text-red-500 group-[.invalid]:border-red-500"
							{...registerSignUp('surname', {
								required: 'Bu alan zorunludur.',
							})}
						/>
						{errorsSignUp.surname && (
							<div className="relative -top-2 inline-flex w-full gap-1 rounded-b-lg p-2 pt-4 text-sm text-white bg-red-500">
								<IoAlertCircle className="h-5 w-5 shrink-0" />
								{errorsSignUp.surname.message}
							</div>
						)}
					</div>

					{/* email */}
					<div className={`group ${errorsSignUp.email && 'invalid'}`}>
						<label className="mb-2 flex gap-1 text-sm font-normal text-slate-700 group-[.invalid]:!text-red-500">
							E-Posta
						</label>
						<input
							type="email"
							placeholder="Lütfen giriniz"
							className="relative z-10 w-full rounded-lg border-2 p-4 text-sm placeholder-slate-400 outline-none transition duration-300 text-slate-500 bg-slate-50 border-slate-200 focus:ring-blue-500 focus:border-blue-500 group-[.invalid]:text-red-500 group-[.invalid]:border-red-500"
							{...registerSignUp('email', {
								required: 'Bu alan zorunludur.',
							})}
						/>
						{errorsSignUp.email && (
							<div className="relative -top-2 inline-flex w-full gap-1 rounded-b-lg p-2 pt-4 text-sm text-white bg-red-500">
								<IoAlertCircle className="h-5 w-5 shrink-0" />
								{errorsSignUp.email.message}
							</div>
						)}
					</div>

					{/* mobileNo */}
					<div
						className={`group ${
							errorsSignUp.mobileNo && 'invalid'
						}`}
					>
						<label className="mb-2 flex gap-1 text-sm font-normal text-slate-700 group-[.invalid]:!text-red-500">
							Telefon Numarası
						</label>
						<input
							type="tel"
							placeholder="Lütfen giriniz"
							className="relative z-10 w-full rounded-lg border-2 p-4 text-sm placeholder-slate-400 outline-none transition duration-300 text-slate-500 bg-slate-50 border-slate-200 focus:ring-blue-500 focus:border-blue-500 group-[.invalid]:text-red-500 group-[.invalid]:border-red-500"
							{...registerSignUp('mobileNo', {
								required: 'Bu alan zorunludur.',
							})}
						/>
						{errorsSignUp.mobileNo && (
							<div className="relative -top-2 inline-flex w-full gap-1 rounded-b-lg p-2 pt-4 text-sm text-white bg-red-500">
								<IoAlertCircle className="h-5 w-5 shrink-0" />
								{errorsSignUp.mobileNo.message}
							</div>
						)}
					</div>

					{/* username */}
					<div
						className={`group ${
							errorsSignUp.username && 'invalid'
						}`}
					>
						<label className="mb-2 flex gap-1 text-sm font-normal text-slate-700 group-[.invalid]:!text-red-500">
							Kullanıcı Adı
						</label>
						<input
							type="text"
							placeholder="Lütfen giriniz"
							className="relative z-10 w-full rounded-lg border-2 p-4 text-sm placeholder-slate-400 outline-none transition duration-300 text-slate-500 bg-slate-50 border-slate-200 focus:ring-blue-500 focus:border-blue-500 group-[.invalid]:text-red-500 group-[.invalid]:border-red-500"
							{...registerSignUp('username', {
								required: 'Bu alan zorunludur.',
							})}
						/>
						{errorsSignUp.username && (
							<div className="relative -top-2 inline-flex w-full gap-1 rounded-b-lg p-2 pt-4 text-sm text-white bg-red-500">
								<IoAlertCircle className="h-5 w-5 shrink-0" />
								{errorsSignUp.username.message}
							</div>
						)}
					</div>

					{/* vendorName */}
					<div
						className={`group ${
							errorsSignUp.vendorName && 'invalid'
						}`}
					>
						<label className="mb-2 flex gap-1 text-sm font-normal text-slate-700 group-[.invalid]:!text-red-500">
							Bayi Adı
						</label>
						<input
							type="text"
							placeholder="Lütfen giriniz"
							className="relative z-10 w-full rounded-lg border-2 p-4 text-sm placeholder-slate-400 outline-none transition duration-300 text-slate-500 bg-slate-50 border-slate-200 focus:ring-blue-500 focus:border-blue-500 group-[.invalid]:text-red-500 group-[.invalid]:border-red-500"
							{...registerSignUp('vendorName', {
								required: 'Bu alan zorunludur.',
							})}
						/>
						{errorsSignUp.vendorName && (
							<div className="relative -top-2 inline-flex w-full gap-1 rounded-b-lg p-2 pt-4 text-sm text-white bg-red-500">
								<IoAlertCircle className="h-5 w-5 shrink-0" />
								{errorsSignUp.vendorName.message}
							</div>
						)}
					</div>

					{/* password */}
					<div
						className={`group ${
							errorsSignUp.password && 'invalid'
						}`}
					>
						<label className="mb-2 flex gap-1 text-sm font-normal text-slate-700 group-[.invalid]:!text-red-500">
							Parola
						</label>
						<input
							type="text"
							placeholder="Lütfen giriniz"
							className="relative z-10 w-full rounded-lg border-2 p-4 text-sm placeholder-slate-400 outline-none transition duration-300 text-slate-500 bg-slate-50 border-slate-200 focus:ring-blue-500 focus:border-blue-500 group-[.invalid]:text-red-500 group-[.invalid]:border-red-500"
							{...registerSignUp('password', {
								required: 'Bu alan zorunludur.',
							})}
						/>
						{errorsSignUp.password && (
							<div className="relative -top-2 inline-flex w-full gap-1 rounded-b-lg p-2 pt-4 text-sm text-white bg-red-500">
								<IoAlertCircle className="h-5 w-5 shrink-0" />
								{errorsSignUp.password.message}
							</div>
						)}
					</div>

					{/* passwordAgain */}
					<div
						className={`group ${
							errorsSignUp.passwordAgain && 'invalid'
						}`}
					>
						<label className="mb-2 flex gap-1 text-sm font-normal text-slate-700 group-[.invalid]:!text-red-500">
							Parola (Tekrar)
						</label>
						<input
							type="text"
							placeholder="Lütfen giriniz"
							className="relative z-10 w-full rounded-lg border-2 p-4 text-sm placeholder-slate-400 outline-none transition duration-300 text-slate-500 bg-slate-50 border-slate-200 focus:ring-blue-500 focus:border-blue-500 group-[.invalid]:text-red-500 group-[.invalid]:border-red-500"
							{...registerSignUp('passwordAgain', {
								required: 'Bu alan zorunludur.',
							})}
						/>
						{errorsSignUp.passwordAgain && (
							<div className="relative -top-2 inline-flex w-full gap-1 rounded-b-lg p-2 pt-4 text-sm text-white bg-red-500">
								<IoAlertCircle className="h-5 w-5 shrink-0" />
								{errorsSignUp.passwordAgain.message}
							</div>
						)}
					</div>
				</div>

				{/* submit */}
				<Button
					type="submit"
					size="md"
					className="mt-6 w-full"
					loading={loadingSignUp}
					disabled={
						Object.keys(errorsSignUp).length > 0 || loadingSignUp
					}
				>
					Üye Ol
				</Button>

				<div className="mt-6 text-center text-sm">
					Zaten üye misin?{' '}
					<NavLink
						to="/giris-yap"
						className="text-blue-700 hover:underline"
					>
						Giriş yap
					</NavLink>
				</div>
			</form>
		</Layout.Public>
	);
};

export default SignUp;
