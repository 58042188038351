import React from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useAxios } from '../../hooks';
import { Button, Modal, Input, Page, Table } from '../../components';

const AcademyCategories = () => {
	const [showEdit, setShowEdit] = React.useState(false);
	const [params, setParams] = useSearchParams();

	/* form */

	const {
		register: registerSaveAcademyCategory,
		setValue: setValueSaveAcademyCategory,
		handleSubmit: handleSubmitSaveAcademyCategory,
		reset: resetSaveAcademyCategory,
		formState: { errors: errorsSaveAcademyCategory },
	} = useForm();

	/* api */

	const api = {
		getAcademyCategories: useAxios(),
		saveAcademyCategory: useAxios(),
		removeAcademyCategory: useAxios(),
	};

	/* getAcademyCategories */

	const getAcademyCategories = () => {
		api.getAcademyCategories.fetch({
			method: 'GET',
			url: '/academy/admin/get/all/video/categories',
		});
	};

	React.useEffect(() => {
		if (api.getAcademyCategories.error) {
			toast.error(api.getAcademyCategories.error.message);
		}
	}, [api.getAcademyCategories.error]);

	React.useEffect(() => {
		getAcademyCategories();
	}, []);

	/* saveAcademyCategory */

	const saveAcademyCategory = (data) => {
		const body = {
			...data,
			...(data.id ? { videoCategoryId: parseInt(data.id, 10) } : null),
		};

		if (data.id) {
			delete body.id;
		}

		api.saveAcademyCategory.fetch({
			method: 'POST',
			url: '/academy/admin/save/video/category',
			data: body,
		});
	};

	React.useEffect(() => {
		if (api.saveAcademyCategory.response) {
			resetSaveAcademyCategory();
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getAcademyCategories();
		}
	}, [api.saveAcademyCategory.response]);

	React.useEffect(() => {
		if (api.saveAcademyCategory.error) {
			toast.error(api.saveAcademyCategory.error.message);
		}
	}, [api.saveAcademyCategory.error]);

	/* edit */

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (showEdit.id && api.getAcademyCategories.response) {
			const { id } = showEdit;
			const item =
				api.getAcademyCategories.response.data[
					api.getAcademyCategories.response.data.findIndex(
						(object) => object.id === id
					)
				];
			const values = [{ name: item.categoryName }];

			values.forEach((value) => {
				setValueSaveAcademyCategory(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});
		}
	}, [showEdit, api.getAcademyCategories.response]);

	/* removeAcademyCategory */

	const removeAcademyCategory = (id) => {
		api.removeAcademyCategory.fetch({
			method: 'POST',
			url: '/academy/admin/delete/video/category',
			data: id,
		});
	};

	React.useEffect(() => {
		if (api.removeAcademyCategory.response) {
			setShowEdit(false);
			toast.success('Silindi.');
			getAcademyCategories();
		}
	}, [api.removeAcademyCategory.response]);

	React.useEffect(() => {
		if (api.removeAcademyCategory.error) {
			toast.error(api.removeAcademyCategory.error.message);
		}
	}, [api.removeAcademyCategory.error]);

	/* return */

	return (
		<Page>
			<Page.Header header="Akademi" title="Eğitim Kategorileri">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>

			<Page.Body
				loading={api.getAcademyCategories.loading}
				show={
					!api.getAcademyCategories.loading &&
					api.getAcademyCategories.response
				}
			>
				<Page.Content>
					<Table>
						<Table.Head data={['Id', 'Kategori', 'Konular', '']} />
						<Table.Body>
							{api.getAcademyCategories.response?.data &&
								api.getAcademyCategories.response.data.map(
									(item, index) => (
										<Table.Row key={index}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column>
												<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
													{item.categoryName}
												</h6>
											</Table.Column>
											<Table.Column>
												<div className="text-base font-medium">
													{item.videoTopicDTOList &&
													Array.isArray(
														item.videoTopicDTOList
													)
														? item.videoTopicDTOList
																.length
														: 0}
												</div>
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<Button
													size="xs"
													variant="secondary-outline"
													className="inline-flex"
													onClick={() => {
														setShowEdit({
															id: item.id,
														});
													}}
												>
													Düzenle
												</Button>
											</Table.Column>
										</Table.Row>
									)
								)}
						</Table.Body>
					</Table>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getAcademyCategories.loading ||
					api.saveAcademyCategory.loading ||
					api.removeAcademyCategory.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					resetSaveAcademyCategory();
				}}
			>
				<form
					onSubmit={handleSubmitSaveAcademyCategory(
						saveAcademyCategory
					)}
					noValidate
				>
					<div className="flex flex-col gap-4 p-6">
						<Input
							type="text"
							name="name"
							label="İsim"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveAcademyCategory}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveAcademyCategory}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveAcademyCategory}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								onClick={() => {
									removeAcademyCategory(showEdit.id);
								}}
								className="w-32"
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={
								Object.keys(errorsSaveAcademyCategory).length >
								0
							}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default AcademyCategories;
