import React from 'react';
import { BiSearch } from 'react-icons/bi';

import Input from '../../../Input';

const Search = ({ show }) =>
	show && (
		<div className="relative col-span-2 md:w-40">
			<Input
				placeholder="Ara"
				size="sm"
				className={{
					input: 'pl-8 !bg-white',
				}}
			/>
			<BiSearch className="pointer-events-none absolute top-1/2 left-3 z-10 h-4 w-4 -translate-y-1/2 transform" />
		</div>
	);

export default Search;
