import React from 'react';

const Badge = ({ children, variant = 'default', className }) => {
	const variants = {
		default: 'bg-slate-50 text-slate-600',
		primary: 'bg-blue-50 text-blue-600',
		success: 'bg-green-50 text-green-600',
		warning: 'bg-amber-50 text-amber-600',
		danger: 'bg-red-50 text-red-600',
	};

	return (
		<div
			className={`inline-flex items-center gap-2 whitespace-nowrap rounded-full py-1 px-3 text-xs font-normal
                ${variants[variant]}
                ${className || ''}
            `}
		>
			{children}
		</div>
	);
};

export default Badge;
