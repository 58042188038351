import React from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

import Context from '../../components/Context';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const useAxios = () => {
	const [context, setContext] = React.useContext(Context);
	const [loading, setLoading] = React.useState(false);
	const [response, setResponse] = React.useState(undefined);
	// const [error, setError] = React.useState(undefined);
	const [error] = React.useState(undefined);

	const fetch = async (params) => {
		const config = params;
		if (
			context?.signed?.time &&
			context?.signed?.data?.expireSecond &&
			context?.signed?.data?.expireSecond <=
				(Date.now() - context.signed.time) / 1000 + 60
		) {
			setContext({});
			localStorage.removeItem('signed');
			toast.error('Oturum süresi doldu.', {
				toastId: 'expired',
			});
			setTimeout(() => window.location.reload(), 1000);
			return;
		}

		// set authorized and unauthorized headers
		const headers = {
			'Content-Type': 'application/json',
			Pragma: 'no-cache',
			Expires: '0',
			...(context?.signed?.data?.token
				? { Authorization: `Bearer ${context?.signed?.data?.token}` }
				: { 'X-Host': 'https://localhost:3000' }),
			...(config.headers ? config.headers : null),
		};

		if (config.headers) {
			delete config.headers;
		}

		try {
			setLoading(true);
			const result = await axios.request({
				headers,
				...config,
			});
			setResponse(result.data);
		} catch (err) {
			// toast.error(err.response.data.message);

			const { id } = toast.error(
				() => (
					<button
						type="button"
						onClick={() => toast.dismiss(id)}
						className="text-left"
					>
						{err.response.data.message
							? err.response.data.message
							: ''}
						{err.response.data.errors &&
						err.response.data.errors[0]?.message
							? err.response.data.errors[0].message
							: ''}
					</button>
				),
				{
					duration: Infinity,
				}
			);

			// setError(err);
		} finally {
			setLoading(false);
		}
	};

	return { loading, response, error, fetch };
};

export default useAxios;
