import React from 'react';
import { CgSpinner } from 'react-icons/cg';

const Button = ({
	children,
	className,
	disabled = false,
	loading = false,
	onClick,
	size = 'md',
	type = 'button',
	variant = 'secondary',
}) => {
	const variants = {
		primary:
			'border-transparent bg-green-500 text-white hover:bg-green-600',
		secondary:
			'border-transparent bg-blue-500 text-white hover:bg-blue-600',
		tertiary:
			'border-transparent bg-slate-500 text-white hover:bg-slate-600',
		danger: 'border-transparent bg-red-500 text-white hover:bg-red-600',
		'primary-outline':
			'border-green-500 bg-white text-green-500 hover:border-green-600 hover:bg-green-50 hover:text-green-600',
		'secondary-outline':
			'border-blue-500 bg-white text-blue-500 hover:border-blue-600 hover:bg-blue-50 hover:text-blue-600',
		'tertiary-outline':
			'border-slate-500 bg-white text-slate-500 hover:border-slate-600 hover:bg-slate-50 hover:text-slate-600',
		'danger-outline':
			'border-red-500 bg-white text-red-500 hover:border-red-600 hover:bg-red-50 hover:text-red-600',
		transparent:
			'border-transparent bg-transparent text-slate-500 hover:text-slate-600',
	};

	const sizes = {
		xs: 'h-7 px-2.5 gap-1.5 text-xs',
		sm: 'h-9 px-3.5 gap-1.5 text-sm',
		md: 'h-10 px-6 gap-1.5 text-sm',
		lg: 'h-12 px-9 gap-1.5 text-base',
		xl: 'h-14 px-12 gap-1.5 text-lg',
	};

	return (
		<button
			type={type} /* eslint-disable-line */
			disabled={disabled}
			onClick={onClick}
			className={`relative flex items-center justify-center whitespace-nowrap rounded-lg border-2 font-normal leading-none transition duration-300 disabled:cursor-not-allowed disabled:opacity-50
				${variants[variant]} 
				${sizes[size]} 
				${className || ''}
			`}
		>
			{loading ? (
				<>
					<span className="text-transparent">{children}</span>
					<span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
						<CgSpinner className="animate-spin" />
					</span>
				</>
			) : (
				children
			)}
		</button>
	);
};

export default Button;
